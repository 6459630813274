var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('AdvancedSearchOverlayStyled', [_c('AdvancedSearchMolecule', {
    attrs: {
      "isSearching": _vm.isSearching,
      "noResults": _vm.noResults,
      "error": _vm.error,
      "searchableEntities": _vm.searchableEntities,
      "searchResultGroups": _vm.searchResultGroups,
      "searchParams": _vm.searchParams
    },
    on: {
      "trigger-search": _vm.search,
      "highlight-previous-result": _vm.highlightPreviousResult,
      "highlight-next-result": _vm.highlightNextResult,
      "select-highlighted-result": _vm.selectHighlightedResult,
      "goto-item": _vm.gotoItem
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }