<template>
  <AdvancedSearchOverlayStyled>
    <AdvancedSearchMolecule
      :isSearching="isSearching"
      :noResults="noResults"
      :error="error"
      :searchableEntities="searchableEntities"
      :searchResultGroups="searchResultGroups"
      :searchParams="searchParams"
      @trigger-search="search"
      @highlight-previous-result="highlightPreviousResult"
      @highlight-next-result="highlightNextResult"
      @select-highlighted-result="selectHighlightedResult"
      @goto-item="gotoItem"
    />
  </AdvancedSearchOverlayStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

import { AdvancedSearchMolecule } from '@common/components'
import { hasTalpaProductOrArchonRoleInToken } from '@common/utils'

import ADVANCED_SEARCH_QUERY from '#/graphql/advancedSearch/advancedSearch.gql'
import ADVANCED_ID_SEARCH_QUERY from '#/graphql/advancedSearch/advancedIdSearch.gql'

const SEARCH_GROUPS = [
  {
    id: 'users',
    type: 'user',
  },
  {
    id: 'assets',
    type: 'asset',
  },
  {
    id: 'organizations',
    type: 'organization',
  },
  {
    id: 'subsidiaries',
    type: 'subsidiary',
  },
  {
    id: 'sites',
    type: 'site',
  },
  {
    id: 'products',
    type: 'product',
    routeName: 'EditProduct',
    paramKey: 'productId',
  },
]

const AdvancedSearchOverlayStyled = styled('div')`
  width: 100%;
  max-width: 600px;
  height: calc(100% - 3rem - 50px);
  padding: 1rem;
  padding-top: 50px;
  overflow: hidden;
`

export default {
  props: {
    args: {
      type: Object,
    },
  },
  components: {
    AdvancedSearchOverlayStyled,
    AdvancedSearchMolecule,
  },
  data() {
    return {
      isSearching: false,
      noResults: false,
      highlightedItemId: null,
      searchResults: [],
      error: null,
      searchParams: undefined,
    }
  },
  computed: {
    searchableEntities() {
      return SEARCH_GROUPS.map(m => ({
        id: m.id,
        label: this.$tc(m.type, 2),
      }))
    },
    hasTalpaProductOrArchonRole() {
      return hasTalpaProductOrArchonRoleInToken(this.$keycloak.token)
    },
    highlightableResults() {
      return this.searchResultGroups.flatMap(m => m.items).filter(f => !f.isCurrentRoute)
    },
    searchResultGroups() {
      return this.searchResults.map(group => ({
        ...group,
        items: group.items.map(item => {
          const isCurrentRoute = this.$route?.name === group?.routeName && this.$route?.params?.id === item.id
          return {
            ...item,
            routeName: group.routeName,
            paramKey: group.paramKey ?? 'id',
            isCurrentRoute,
            isHighlighted: !isCurrentRoute && item.id === this.highlightedItemId,
          }
        }),
      }))
    },
  },
  methods: {
    async advancedSearch(searchQuery) {
      const { data } = await this.$apollo.query({
        query: ADVANCED_SEARCH_QUERY,
        variables: {
          searchQuery,
          includeUser: this.hasTalpaProductOrArchonRole,
        },
      })
      if (!data) {
        return []
      } else {
        return SEARCH_GROUPS.reduce((groups, group) => {
          const items = data[group.id]
          if (items.length > 0) {
            groups.push({
              id: group.id,
              type: group.type,
              i18nKey: group.i18nKey ?? group.type,
              routeName: group.routeName ?? group.type,
              paramKey: group.paramKey ?? 'id',
              items,
            })
          }
          return groups
        }, [])
      }
    },
    async advancedIdSearch({ id, isUuid }) {
      const { data } = await this.$apollo.query({
        query: ADVANCED_ID_SEARCH_QUERY,
        variables: {
          id,
          idString: id,
          includeUser: this.hasTalpaProductOrArchonRole && isUuid,
          isUuid,
        },
      })
      if (!data) {
        return null
      } else {
        return data
      }
    },
    async search(params) {
      this.isSearching = true
      try {
        this.searchParams = params
        if (params.id) {
          this.searchResults = []
          const results = await this.advancedIdSearch(params)
          Object.keys(results).some(key => {
            if (results[key]?.id !== undefined && results[key]?.name !== 'deleted user') {
              const group = SEARCH_GROUPS.find(f => f.type === key)
              if (group) {
                this.gotoItem({
                  id: results[key]?.id,
                  routeName: group.routeName ?? group.type,
                  paramKey: group.paramKey ?? 'id',
                })
              } else {
                throw new Error(`no matching group for result.${key}`)
              }
              return true
            }
            return false
          })
        } else if (params?.query !== undefined) {
          if (params.query === '') {
            this.searchResults = []
            this.noResults = false
          } else {
            this.searchResults = await this.advancedSearch(params.query)
            this.noResults = this.searchResults < 1
          }
        }
        this.error = null
      } catch (err) {
        this.searchResults = []
        this.error = err
        this.noResults = false
      }
      this.isSearching = false
    },
    highlightPreviousResult() {
      if (this.highlightableResults.length === 0) {
        this.highlightedItemId = null
        return
      }
      if (this.highlightableResults.length === 1) {
        this.highlightedItemId = this.highlightableResults[0]?.id ?? null
        return
      }
      const currentIdx = this.highlightableResults.findIndex(f => f.isHighlighted)
      if (currentIdx === -1) {
        this.highlightedItemId = this.highlightableResults[this.highlightableResults.length - 1]?.id ?? null
      } else {
        const idx = currentIdx - 1 >= 0 ? currentIdx - 1 : this.highlightableResults.length - 1
        this.highlightedItemId = this.highlightableResults[idx]?.id ?? null
      }
    },
    highlightNextResult() {
      if (this.highlightableResults.length === 0) {
        this.highlightedItemId = null
        return
      }
      if (this.highlightableResults.length === 1) {
        this.highlightedItemId = this.highlightableResults[0]?.id ?? null
        return
      }
      const currentIdx = this.highlightableResults.findIndex(f => f.isHighlighted)
      if (currentIdx === -1) {
        this.highlightedItemId = this.highlightableResults[0]?.id ?? null
      } else {
        const idx = currentIdx + 1 <= this.highlightableResults.length - 1 ? currentIdx + 1 : 0
        this.highlightedItemId = this.highlightableResults[idx]?.id ?? null
      }
    },
    selectHighlightedResult() {
      const highlighted = this.highlightableResults.find(f => f.isHighlighted)
      if (highlighted) {
        this.gotoItem(highlighted)
      }
    },
    gotoItem(item) {
      if (item?.id && item?.routeName) {
        const paramKey = item.paramKey ?? 'id'
        if (this.$route?.name !== item.routeName || this.$route?.params?.[paramKey] !== item.id) {
          this.$router.push({
            name: item.routeName,
            params: {
              [paramKey]: item.id,
            },
          })
        }
        this.$root.$emit('closeOverlay')
      }
    },
  },
}
</script>
